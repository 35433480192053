@import "fonts";

@blue: #3F51B5;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}

.date,
.user {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: 300;
  padding-left: 15px;

  &:before {
    position: absolute;
    display: inline-block;
    content: '';
    width: 10px;
    height: 10px;
    left: 0;
    top: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 5px;
  }
}
.date {
  &:before {
    background-image: url('../img/icon-calendar.svg');
  }
}
.user {
  &:before {
    background-image: url('../img/icon-user.svg');
  }
}

header {
  width: 100%;
  padding: 12px 0 16px;

  .header {
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 15px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: @blue;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__logo {
      display: block;
      height: 50px;
      width: auto;
      text-align: center;
      border-bottom: 4px solid #000;
      padding-bottom: 16px;
      margin-bottom: 16px;
      
      img {
        height: 100%;
        width: auto;
      }
    }
    &__nav {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
      }
      li {
        margin: 0 45px;
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
}

main {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.head-news {
  &__image {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
  &__title {
    font-size: 22px;
    font-weight: 700;
  }
  &__meta {
    font-weight: 300;
    margin-bottom: 16px;

    span {
      display: inline-block;

      &:first-child {
        margin-right: 14px;
      }
    }
  }
  &__text {
    font-size: 14px;
    margin-bottom: 16px;
  }
  &__link {
    display: block;
    float: right;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: @blue;
    padding: 10px 45px;
    border-radius: 5px;
  }
}

.content {
  flex: 1;
  margin-bottom: 30px;

  & > * {
    margin-bottom: 16px;
  }
  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  table {
    width: 100%;
    max-width: 60%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;

    td, th {
      padding: 5px;
      border: 1px solid #000;
      text-align: center;
    }
  }
}

aside {
  width: 510px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #E2E2E2;
  padding-top: 16px;
  margin: 0 0 20px 30px;

  .sidebar-block {
    margin-bottom: 40px;

    &__title {
      display: block;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    &__list {
      list-style: none;

      li {
        margin-bottom: 16px;

        &.current {
          a {
            text-decoration: underline;
          }
        }
        a {
          font-size: 20px;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

footer {
  padding: 5px 0;

  .copyright {
    font-weight: 500;
    text-align: center;
  }
}

@import "media";