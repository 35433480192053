@media screen and (max-width: 1119px) {
  header {
    .header {
      &__nav {
        li {
          margin: 0 15px;
        }
      }
    }
  }
  main {
    flex-direction: column;
  }
  aside {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(27deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-27deg);
              transform-origin: left;
            }
          }
        }
      }
      &__logo {
        border-bottom: none;
        padding: 0;
        margin: 0;
        height: 30px;
      }

      &__nav {
        position: absolute;
        top: -20px;
        left: 50px;
        padding: 20px;
        width: 200px;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        z-index: 9999;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          li {
            margin: 0 0 16px 0;
          }
        }
      }
    }
  }

  .content {
    table {
      max-width: 100%;
    }
  }
}