@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Robotothin.woff2") format("woff2"), url("../fonts/Robotothin.woff") format("woff"), url("../fonts/Robotothin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Roboto.woff2") format("woff2"), url("../fonts/Roboto.woff") format("woff"), url("../fonts/Roboto.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Robotomedium.woff2") format("woff2"), url("../fonts/Robotomedium.woff") format("woff"), url("../fonts/Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Robotobold.woff2") format("woff2"), url("../fonts/Robotobold.woff") format("woff"), url("../fonts/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}
.date,
.user {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: 300;
  padding-left: 15px;
}
.date:before,
.user:before {
  position: absolute;
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  left: 0;
  top: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 5px;
}
.date:before {
  background-image: url('../img/icon-calendar.svg');
}
.user:before {
  background-image: url('../img/icon-user.svg');
}
header {
  width: 100%;
  padding: 12px 0 16px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 15px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #3F51B5;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__logo {
  display: block;
  height: 50px;
  width: auto;
  text-align: center;
  border-bottom: 4px solid #000;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}
header .header__nav li {
  margin: 0 45px;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
main {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.head-news__image {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}
.head-news__title {
  font-size: 22px;
  font-weight: 700;
}
.head-news__meta {
  font-weight: 300;
  margin-bottom: 16px;
}
.head-news__meta span {
  display: inline-block;
}
.head-news__meta span:first-child {
  margin-right: 14px;
}
.head-news__text {
  font-size: 14px;
  margin-bottom: 16px;
}
.head-news__link {
  display: block;
  float: right;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #3F51B5;
  padding: 10px 45px;
  border-radius: 5px;
}
.content {
  flex: 1;
  margin-bottom: 30px;
}
.content > * {
  margin-bottom: 16px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 22px;
}
.content h2 {
  font-size: 20px;
}
.content h3 {
  font-size: 18px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content table {
  width: 100%;
  max-width: 60%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}
.content table td,
.content table th {
  padding: 5px;
  border: 1px solid #000;
  text-align: center;
}
aside {
  width: 510px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #E2E2E2;
  padding-top: 16px;
  margin: 0 0 20px 30px;
}
aside .sidebar-block {
  margin-bottom: 40px;
}
aside .sidebar-block__title {
  display: block;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 16px;
}
aside .sidebar-block__list {
  list-style: none;
}
aside .sidebar-block__list li {
  margin-bottom: 16px;
}
aside .sidebar-block__list li.current a {
  text-decoration: underline;
}
aside .sidebar-block__list li a {
  font-size: 20px;
  font-weight: 500;
}
aside .sidebar-block__list li a:hover {
  text-decoration: underline;
}
footer {
  padding: 5px 0;
}
footer .copyright {
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 1119px) {
  header .header__nav li {
    margin: 0 15px;
  }
  main {
    flex-direction: column;
  }
  aside {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(27deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-27deg);
    transform-origin: left;
  }
  header .header__logo {
    border-bottom: none;
    padding: 0;
    margin: 0;
    height: 30px;
  }
  header .header__nav {
    position: absolute;
    top: -20px;
    left: 50px;
    padding: 20px;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 9999;
  }
  header .header__nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .header__nav ul li {
    margin: 0 0 16px 0;
  }
  .content table {
    max-width: 100%;
  }
}
