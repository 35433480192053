@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Robotothin.woff2") format("woff2"),
      url("../fonts/Robotothin.woff") format("woff"),
      url("../fonts/Robotothin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Roboto.woff2") format("woff2"),
      url("../fonts/Roboto.woff") format("woff"),
      url("../fonts/Roboto.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Robotomedium.woff2") format("woff2"),
      url("../fonts/Robotomedium.woff") format("woff"),
      url("../fonts/Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Robotobold.woff2") format("woff2"),
      url("../fonts/Robotobold.woff") format("woff"),
      url("../fonts/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}